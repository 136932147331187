import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { Loader } from 'rsuite';

import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Text from "../../components/UI/Text/Text";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import { DEMO_TABLE_ID } from "../../utils/constants";
import { InvoicePlaceholder } from "../../components/Placeholder/Placeholder";

import styles from './Table.module.css';

const Table = ({ table, order, isLoaded }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (order) {
      if (order.table.id === DEMO_TABLE_ID) {
        setTimeout(() => {
          navigate(`/invoice/${order.table.id}`);
        }, 3000);
      } else {
        navigate(`/invoice/${order.table.id}`);
      }
    }
  }, [order]);

  if (!isLoaded) {
    return (
      <InvoicePlaceholder />
    )
  }

  return (
    <>
      <Header logo />

      <Main>
        <div className={`${styles.Home}`}>
          <div className={styles.Table}>
            <Text>{t('Table', { number: table.number })}</Text>
          </div>
          <div className={styles.Title}>
            <Title bolder>
              <Trans i18nKey="Your table has not yet been invoiced" />
            </Title>
          </div>

          <div className={styles.TextsList}>
            <Text className={styles.TextsListItem}>
              {t('Most likely you haven\'t ordered anything yet. Please see our menu to make your order.')}
            </Text>
            <Text className={styles.TextsListItem}>
              {t('If you have already made an order, you should wait for a little while the bill is displayed in the system.')}
            </Text>
          </div>

          <div className={styles.Loader}>
            <Loader size="md" />
          </div>

          <Button
            className={styles.MenuButton}
            appearance="dark"
            onClick={() => {
              navigate(`/menu/${table.id}`)
            }}
          >
            {t('View menu')}
          </Button>
        </div>

        <PoweredBy/>
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <Table />
    </Wrapper>
  )
};
