import React from 'react';
import cx from 'classnames';
import { Button } from 'rsuite';

import styles from './Button.module.scss';

const MyButton = ({ appearance, className, children, ...rest }) => {
  const customAppearances = ['dark', 'light', 'transparent'];

  return (
    <Button
      appearance={customAppearances.includes(appearance) ? 'default' : appearance}
      className={cx(styles.Button, styles[`Button_${appearance}`], className)}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default MyButton;
