import React, { useState, useEffect } from 'react';
import { Icon } from '@rsuite/icons';
import { useNavigate, useParams } from "react-router-dom";
import {
  FaEuroSign as FaEuroSignIcon
} from 'react-icons/fa';
import _ from "lodash";
import { InputPicker, IconButton, Checkbox } from 'rsuite';
import { useTranslation } from 'react-i18next';
import {
  AiOutlinePlus as AiOutlinePlusIcon,
  AiOutlineMinus as AiOutlineMinusIcon,
} from 'react-icons/ai';

import Modal from "../../components/UI/Modal/Modal";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import Text from "../../components/UI/Text/Text";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Wrapper from "../../components/Wrapper/Wrapper";
import { CURRENCIES } from "../../utils/constants";
import {formatPrice, getOrderCost, getPaymentLeft} from "../../utils/common";
import { InvoicePlaceholder } from "../../components/Placeholder/Placeholder";

import styles from './SplitBill.module.scss';

const PayByItemsModal = ({ isVisible, onClose, order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [cost, setCost] = useState(0);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const cost = Object.keys(selected).reduce((curr, next) => {
      return curr + selected[next] * order.items[next].price;
    }, 0);

    setCost(cost);
  }, [selected]);

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title={<Title bolder>{t('Pay by items')}</Title>}
      classes={{
        modal: styles.Modal,
        body: styles.ModalBody,
        header: styles.ModalHeader,
      }}
      footer={(
        <div className={styles.ModalFooter}>
          <div className={styles.total}>
            <div className={styles.row}>
              <Title small bolder>{t('Total')}</Title>
              <div className={`${styles.price} ${styles.priceBold}`}>
                {formatPrice(cost, CURRENCIES.EURO)}
              </div>
            </div>
          </div>

          <Button
            appearance="dark"
            onClick={() => {
              navigate(`/bill/${order.table.id}?customAmount=${cost}`);
            }}
          >
            {t('Confirm')}
          </Button>
        </div>
      )}
    >
      <Text>{t('Please select items from the list for which you want will pay')}</Text>

      <div className={styles.list}>
        {order.items.map((item, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.row}>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  className={styles.Checkbox}
                  onChange={(e, checked) => {
                    if (checked) {
                      setSelected({ ...selected, [index]: item.quantity })
                    } else {
                      setSelected(_.omit(selected, index));
                    }
                  }}
                />
              </div>
              <InputPicker
                data={new Array(item.quantity).fill('').map((_, index) => ({ value: index + 1, label: `x ${index + 1}` }))}
                value={selected[index]}
                disabled={!Object.keys(selected).includes(String(index))}
                onChange={(value) => {
                  setSelected({ ...selected, [index]: Number(value) })
                }}
                style={{ width: 70 }}
                cleanable={false}
                placeholder={() => <></>}
                size="xs"
              />
            </div>
            <div className={`${styles.row} ${styles.info}`}>
              <Text>{item.name}</Text>
              <div className={styles.price}>{formatPrice(item.price, CURRENCIES.EURO)}</div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

const SplitBill = ({ order, isLoaded }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const [isPayCustomModalVisible, setIsPayCustomModalVisible] = useState(false);
  const [isSplitEvenlyModalVisible, setIsSplitEvenlyModalVisible] = useState(false);
  const [isSplitByItemsModalVisible, setIsSplitByItemsModalVisible] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);

  const [guests, setGuests] = useState(2);

  const onChangeGuests = (value) => {
    if (!!value) {
      setGuests(value);
    }
  }

  if (!isLoaded) {
    return (
      <InvoicePlaceholder />
    )
  }

  if (!order) {
    navigate(`/table/${params.table}`);
    return null;
  }

  const cost = getPaymentLeft(order);

  return (
    <>
      <Header logo />

      <Main>
        <Modal
          isVisible={isPayCustomModalVisible}
          onClose={() => setIsPayCustomModalVisible(false)}
          title={<Title bolder>{t('Pay custom amount')}</Title>}
        >
          <Text>{t('The maximum custom amount for for your table is', { cost: formatPrice(cost, CURRENCIES.EURO) })}</Text>

          <div className={styles.input}>
            <Input
              type="number"
              name="amount"
              id="amount"
              inputMode="numeric"
              placeholder={t('Enter custom amount')}
              label={t('Custom Amount')}
              required
              icon={<Icon as={FaEuroSignIcon} size="1.5em" />}
              onChange={(e) => setCustomAmount(e.target.value)}
            />
          </div>
          <Button
            disabled={!customAmount}
            appearance="dark"
            onClick={() => {
              navigate(`/bill/${order.table.id}?customAmount=${customAmount}`);
            }}
          >
            {t('Confirm')}
          </Button>
        </Modal>

        <Modal
          isVisible={isSplitEvenlyModalVisible}
          onClose={() => setIsSplitEvenlyModalVisible(false)}
          title={<Title bolder>{t('Split evenly')}</Title>}
        >
          <div className={styles.text}><Text>{t('Select the number of guests')}</Text></div>
          <div className={styles.box}>
            <div className={styles.controls}>
              <IconButton
                size="lg"
                circle
                className={styles.btn}
                icon={<AiOutlineMinusIcon />}
                onClick={() => onChangeGuests(guests - 1)}
              />
              <div className={styles.qty}>{guests}</div>
              <IconButton
                size="lg"
                circle
                className={styles.btn}
                icon={<AiOutlinePlusIcon />}
                onClick={() => onChangeGuests(guests + 1)}
              />
            </div>
            <div className={styles.total}>
              <Text><span className={styles.totalText}>{t('each', { cost: formatPrice(cost / guests, CURRENCIES.EURO) })}</span></Text>
            </div>
          </div>
          <Button
            appearance="dark"
            onClick={() => {
              navigate(`/bill/${order.table.id}?customAmount=${parseFloat(cost / guests).toFixed(2)}`);
            }}
          >
            {t('Confirm')}
          </Button>
        </Modal>

        <PayByItemsModal
          isVisible={isSplitByItemsModalVisible}
          onClose={() => setIsSplitByItemsModalVisible(false)}
          order={order}
        />

        <div className={styles.split}>
          <div className={styles.splitTable}>
            <Text>{t('Table', { number: order.table.number })}</Text>
          </div>
          <div className={styles.splitTitle}>
            <Title>{t('Split the bill _cost', { cost: formatPrice(cost, CURRENCIES.EURO) })}</Title>
          </div>
          <div className={styles.splitText}>
            <Text>{t('Select how to split for your entire table, then encourage other guests to pay')}</Text>
          </div>

          <div className={styles.splitBtns}>
            <Button
              appearance="dark"
              onClick={() => setIsPayCustomModalVisible(true)}
            >
              {t('Pay custom amount')}
            </Button>
            <Button
              appearance="light"
              onClick={() => setIsSplitByItemsModalVisible(true)}
            >
              {t('Pay by items')}
            </Button>
            <Button
              appearance="transparent"
              onClick={() => setIsSplitEvenlyModalVisible(true)}
            >
              {t('Split evenly')}
            </Button>
          </div>
        </div>

        <PoweredBy/>
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <SplitBill />
    </Wrapper>
  )
};

