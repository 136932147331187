import React, { useRef } from 'react';
import i18next from 'i18next';
import { useNavigate } from "react-router-dom";
import {
  AiOutlineMenu as AiOutlineMenuIcon
} from 'react-icons/ai';
import {
  BiArrowBack as BiArrowBackIcon
} from 'react-icons/bi';
import { Whisper, Popover } from 'rsuite';
import esFlagImageUrl from './images/es.svg';
import usFlagImageUrl from './images/us.svg';
import ruFlagImageUrl from './images/ru.svg';

import styles from './Header.module.scss';

import Logo from "./Logo/Logo";

const CurrentLanguage = () => {
  if (i18next.language === 'ru') {
    return (
      <div className={styles.CurrentLanguage}>
        <img src={ruFlagImageUrl}/>
      </div>
    )
  } else if (i18next.language === 'en') {
    return (
      <div className={styles.CurrentLanguage}>
        <img src={usFlagImageUrl}/>
      </div>
    )
  } else if (i18next.language === 'es') {
    return (
      <div className={styles.CurrentLanguage}>
        <img src={esFlagImageUrl}/>
      </div>
    )
  }

  return null
}

const Header = (props) => {
  const navigate = useNavigate();
  const popoverRef = useRef();

  return (
    <div className={`${styles.Header} ${props.className}`}>
      <div className={styles.Back} onClick={() => navigate(-1)}>
        <BiArrowBackIcon  />
      </div>

      <div className={styles.Title}>
        {props.title}
      </div>

      {props.logo && (
        <Logo />
      )}

      <Whisper
        trigger="click"
        placement="bottomEnd"
        ref={popoverRef}
        speaker={(
          <Popover className={styles.Popover}>
            <div className={styles.LanguageRow}>
              <div
                className={styles.LanguageItem}
                onClick={() => {
                  i18next.changeLanguage('es');
                  popoverRef.current.close();
                }}
              >
                <img
                  src={esFlagImageUrl}
                />
              </div>
              <div
                className={styles.LanguageItem}
                onClick={() => {
                  i18next.changeLanguage('en');
                  popoverRef.current.close();
                }}
              >
                <img
                  src={usFlagImageUrl}
                />
              </div>
              <div
                className={styles.LanguageItem}
                onClick={() => {
                  i18next.changeLanguage('ru');
                  popoverRef.current.close();
                }}
              >
                <img
                  src={ruFlagImageUrl}
                />
              </div>
            </div>
          </Popover>
        )}
      >
        <div className={styles.Menu}>
          <CurrentLanguage />
        </div>
      </Whisper>
    </div>
  );
}

export default Header;
