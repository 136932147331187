import React, { cloneElement, useEffect, useState } from 'react';
import { collection, onSnapshot, query, where, doc, getDoc, updateDoc, getDocs } from "firebase/firestore"
import { useParams } from "react-router-dom";

import { db } from "../../index";
import { DEMO_TABLE_ID } from "../../utils/constants";
import apiClient from "../../utils/apiClient";

import styles from './Wrapper.module.css';

function Wrapper(props) {
  const [restaurant, setRestaurant] = useState(null);
  const [table, setTable] = useState(null);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const params = useParams();

  useEffect(() => {
    if (table && !window.isPollingForOrders) {
      window.isPollingForOrders = true;

      setInterval(() => {
        apiClient.get(`/table/${table.id}/orders?clientId=1`);
      }, 5000);
    }
  }, [table])

  useEffect(async () => {
    setIsLoading(true);

    const tableRef = doc(db, 'tables', params.table);
    const tableDoc = await getDoc(tableRef);
    const table = tableDoc.data();
    setTable({ id: tableDoc.id, ...table });

    const restaurantDoc = await getDoc(table.restaurant);
    setRestaurant({ id: restaurantDoc.id, ...restaurantDoc.data() });

    if (tableDoc.id === DEMO_TABLE_ID) {
      const ordersQ = query(collection(db, "orders"), where("table", "==", tableRef), where("status", "==", "paid"));
      const querySnapshot = await getDocs(ordersQ)

      const promises = [];
      querySnapshot.forEach((doc) => {
        promises.push(updateDoc(doc.ref, { status: 'active' }, { merge: true }));
      });

      await Promise.all(promises);
    }

    const q = query(collection(db, "orders"), where("table", "==", tableRef), where("status", "in", ['active', 'local']));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
      querySnapshot.forEach((doc) => {
        orders.push({ id: doc.id, ...doc.data() });
      });

      setOrder(orders[0]);

      setIsLoading(false);
    });

    return unsubscribe
  }, []);

  const isLoaded = isLoading === false;

  return (
    <div className={styles.Wrapper}>
      {cloneElement(props.children, {
        order,
        table,
        restaurant,
        isLoaded,
      })}
    </div>
  );
}

export default Wrapper;
