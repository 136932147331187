import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { InputNumber, InputGroup, IconButton, Badge } from 'rsuite';
import _ from 'lodash';
import {
  BsTrash as BsTrashIcon
} from 'react-icons/bs';
import { useFormik } from 'formik';
import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import { formatPrice, getOrderCost } from "../../utils/common";
import { CURRENCIES } from "../../utils/constants";
import apiClient from "../../utils/apiClient";
import { MenuPositionsPlaceholder, MenuTopPlaceholder } from "../../components/Placeholder/Placeholder";
import Title from "../../components/UI/Title/Title";
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/UI/Button/Button";

import styles from './Menu.module.scss';

const Menu = ({ table, order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(null);
  const [isProductsLoading, setIsProductsLoading] = useState(null);
  const [productModal, setProductModal] = useState({ isVisible: false, data: {} })

  useEffect(async () => {
    setIsCategoriesLoading(true);
    const responseCategories = await apiClient.get('/menu-categories?clientId=1&fake=1');
    setCategories(responseCategories.data.menuCategories);
    setSelectedCategory(responseCategories.data.menuCategories[0]);
    setIsCategoriesLoading(false);
  }, []);

  useEffect(async () => {
    if (selectedCategory) {
      setIsProductsLoading(true);
      const responseProducts = await apiClient.get(`/menu-products?clientId=1&categoryId=${selectedCategory.id}&fake=1`);

      setProducts(responseProducts.data.menuProducts);
      setIsProductsLoading(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (order?.status === 'local' && isCategoriesLoading === false) {
      const orderCategories = order.items.reduce((curr, next) => ([...curr, next.categoryId]), []);

      const categoriesWithOrder = categories.map((item) => ({
        ...item,
        isInOrder: orderCategories.includes(item.id),
      }));

      setCategories(_.orderBy(categoriesWithOrder, ['isInOrder'], ['desc']));
    }
  }, [order, isCategoriesLoading])

  const isCategoriesLoaded = isCategoriesLoading === false;
  const isProductsLoaded = isProductsLoading === false;

  const orderItemsMap = _.keyBy(order?.items, 'id');

  const formik = useFormik({
    initialValues: {
      id: null,
      name: null,
      quantity: 1,
      price: null,
      categoryId: null,
    },
    onSubmit: async (values, actions) => {
      const orderItemsToSubmitMap = _.omitBy({
        ...orderItemsMap,
        [values.id]: values
      }, (item) => !item.quantity)

      await apiClient.post('/order/local', {
        tableId: table.id,
        items: Object.keys(orderItemsToSubmitMap).map((item) => orderItemsToSubmitMap[item]),
      });

      setProductModal({ isVisible: false, data: {} });

      actions.setSubmitting(false);
    },
    validate: (values) => {
      const errors = {};

      return errors;
    },
  });

  return (
    <>
      <Header title={t('Menu')} />

      <Main className={styles.Main}>
        <Modal
          isVisible={productModal.isVisible}
          onClose={() => setProductModal({ isVisible: false, data: {} })}
          title={<Title bolder>{productModal.data.product?.name}</Title>}
          classes={{
            body: styles.ModalBody,
          }}
        >
          <form onSubmit={formik.handleSubmit} className={styles.ProductForm}>
            <div className={styles.QuantitySubmitRow}>
              <InputGroup className={styles.QuantityInputGroup}>
                <InputGroup.Button
                  onClick={() => {
                    if (formik.values.quantity > 1) {
                      formik.setFieldValue('quantity', formik.values.quantity - 1)
                    }
                  }}
                >
                  -
                </InputGroup.Button>
                <InputNumber
                  className={styles.QuantityInput}
                  value={formik.values.quantity}
                />
                <InputGroup.Button
                  onClick={() => {
                    formik.setFieldValue('quantity', formik.values.quantity + 1)
                  }}
                >
                  +
                </InputGroup.Button>
              </InputGroup>

              <Button
                className={styles.SubmitButton}
                type="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                appearance="dark"
              >
                {t('Add to the bill')}
              </Button>

              {orderItemsMap[formik.values.id]?.quantity && (
                <IconButton
                  className={styles.RemoveButton}
                  icon={<BsTrashIcon />}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    formik.setFieldValue('quantity', 0);
                    formik.handleSubmit();
                  }}
                />
              )}
            </div>
          </form>
        </Modal>

        {isCategoriesLoaded ? (
          <div className={styles.Menu}>
            {categories.map((item, index) => {
              const isSelected = selectedCategory.id === item.id;

              const CategoryItem = ({ ...restProps }) => {
                return (
                  <div
                    className={`${styles.MenuItem} ${isSelected ? styles.MenuItem_selected : ''}`}
                    onClick={() => setSelectedCategory(item)}
                    {...restProps}
                  >
                    {item.name}
                  </div>
                )
              }

              if (item.isInOrder) {
                return (
                  <Badge key={index} content={null}>
                    <CategoryItem />
                  </Badge>
                )
              }

              return (
                <CategoryItem key={index} />
              )
            })}
          </div>
        ) : (
          <MenuTopPlaceholder />
        )}

        {isProductsLoaded ? (
          <div className={styles.ProductsList}>
            {products.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.ProductItem}
                  onClick={() => {
                    if (order?.status === 'active') return false;

                    setProductModal({ isVisible: true, data: { product: item } });

                    formik.setValues({
                      id: item.id,
                      name: item.name,
                      price: item.price,
                      quantity: orderItemsMap[item.id] ? orderItemsMap[item.id].quantity : 1,
                      categoryId: item.categoryId,
                    });
                  }}
                >
                  <div className={styles.top}>
                    {orderItemsMap[item.id] ? (
                      <Badge content={orderItemsMap[item.id].quantity}>
                        <div className={styles.title}>
                          {item.name}
                        </div>
                      </Badge>
                    ) : (
                      <div className={styles.title}>
                        {item.name}
                      </div>
                    )}
                    <div className={styles.price}>
                      {formatPrice(item.price, CURRENCIES.EURO)}
                    </div>
                  </div>

                  {item.modifications && (
                    <div className={styles.text}>{item.modifications.map((item) => item.modificator_name).join(', ')}</div>
                  )}
                  {item.ingredients && (
                    <div className={styles.text}>{item.ingredients.map((item) => item.ingredient_name).join(', ')}</div>
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <MenuPositionsPlaceholder />
        )}

        {order?.status === 'local' && (
          <div className={styles.SubmitOrder}>
            <Button
              appearance="dark"
              onClick={async () => {
                await apiClient.post('/order/submit', {
                  orderId: order.id,
                  clientId: 1,
                });

                navigate(`/invoice/${table.id}`);
              }}
            >
              {t('Submit the order', { cost: formatPrice(getOrderCost(order), CURRENCIES.EURO) })}
            </Button>
          </div>
        )}
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <Menu />
    </Wrapper>
  )
};

