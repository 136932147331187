import Title from "../UI/Title/Title";
import { formatPrice } from "../../utils/common";
import { CURRENCIES } from "../../utils/constants";

const OrderCost = ({ total, left }) => {
  if (total === left) {
    return (
      <Title bolder>{formatPrice(total, CURRENCIES.EURO)}</Title>
    )
  }

  return (
    <Title bolder>
      {formatPrice(left, CURRENCIES.EURO)}&nbsp;<small>({formatPrice(total, CURRENCIES.EURO)})</small>
    </Title>
  )
}

export default OrderCost;
