import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import App from './App';

import './utils/i18n';

import 'rsuite/dist/rsuite.min.css';

import './index.css';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD0dGaGRKq2sZUOE7Dn5fSpwS8V9sUVeUw",
  authDomain: "pronto-8a515.firebaseapp.com",
  projectId: "pronto-8a515",
  storageBucket: "pronto-8a515.appspot.com",
  messagingSenderId: "1095645947761",
  appId: "1:1095645947761:web:ea0cf1c7670d3b9416f8d0",
  measurementId: "G-01SWQ2GGTT"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const db = getFirestore()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
