import React  from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Title from "../../components/UI/Title/Title";
import Text from "../../components/UI/Text/Text";
import Button from "../../components/UI/Button/Button";
import iconImageUrl from './icon.svg';

import styles from './PayFailed.module.scss';

const PayFailed = ( ) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <Main>
        <div className={styles.Content}>
          <img className={styles.Image} src={iconImageUrl} />

          <Title className={styles.Title}>{t('Payment Failed')}</Title>
          <Text className={styles.Description}>{t('Something went wrong')}</Text>

          <div className={styles.ButtonsList}>
            <Button
              appearance="light"
              onClick={() => {
                navigate(`/table/${params.table}`);
              }}
            >
              {t('Back')}
            </Button>
          </div>
        </div>

        <PoweredBy />
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <PayFailed />
    </Wrapper>
  )
};
