import React from 'react';
import styles from './Text.module.css';

function Text({ className, children }) {
  return (
    <div className={`${styles.Text} ${className}`}>{children}</div>
  );
}

export default Text;
