import React, { useEffect } from 'react';
import cx from 'classnames';
import { Animation, IconButton } from 'rsuite';
import {
  AiOutlineCloseCircle as AiOutlineCloseCircleIcon,
} from 'react-icons/ai';

import styles from './Modal.module.scss';

const Overlay = ({ ...restProps }) => <div className={styles.Overlay} {...restProps} />

function Modal({ isVisible, onClose, title, footer = null, children, classes = {} }) {
  useEffect(() => {
    if (isVisible) {
      document.querySelector('body').classList.add("modal-open");
    } else {
      document.querySelector('body').classList.remove("modal-open");
    }
  }, [isVisible])

  return (
    <>
      {isVisible && (
        <Overlay onClick={onClose} />
      )}
      <Animation.Slide
        in={isVisible}
        placement="bottom"
        unmountOnExit
      >
        <div className={cx(styles.Modal, classes.modal)}>
          <div className={cx(styles.Header, classes.header)}>
            <div className={styles.Title}>
              {title}
            </div>
            <IconButton
              className={styles.CloseButton}
              size="lg"
              icon={<AiOutlineCloseCircleIcon />}
              onClick={onClose}
            />
          </div>

          <div className={classes.body}>
            {children}
          </div>

          {footer}
        </div>
      </Animation.Slide>
    </>
  );
}

export default Modal;
