import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'rsuite';
import qs from 'query-string';
import {useParams, useNavigate, useLocation} from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Title from "../../components/UI/Title/Title";
import Text from "../../components/UI/Text/Text";
import { DEMO_TABLE_ID } from "../../utils/constants";
import apiClient from "../../utils/apiClient";

import styles from './PaymentAwaiting.module.scss';

const PaymentAwaiting = ({ table, order }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { transactionId } = qs.parse(location.search);

  useEffect(() => {
    const method = async () => {
      if (order) {
        if (table.id === DEMO_TABLE_ID) {
          setTimeout(() => {
            navigate(`/pay-success/${table.id}/${order.id}/${transactionId}`);
          }, 3000);
        } else {
          try {
            await apiClient.post(`/payment/post-payment`, {
              orderId: order.id,
              transactionId: Number(transactionId),
              clientId: 1,
            });

            navigate(`/pay-success/${table.id}/${order.id}/${transactionId}`);
          } catch (e) {
            navigate(`/pay-failed/${table.id}`);
          }
        }
      }
    }

    method()
  }, [order?.id, table]);

  return (
    <>
      <Header/>
      <Main>
        <div className={styles.Content}>
          <Loader size="lg" speed="slow" />

          <Title className={styles.Title}>{t('Waiting for payment to proceed')}</Title>
          <Text className={styles.Subtitle}>{t('it might take a while')}</Text>
        </div>
        <PoweredBy />
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <PaymentAwaiting />
    </Wrapper>
  )
};
