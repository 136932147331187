import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Wrapper from "../../components/Wrapper/Wrapper";
import Text from "../../components/UI/Text/Text";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import { getOrderCost, getPaymentLeft } from "../../utils/common";
import { InvoicePlaceholder } from "../../components/Placeholder/Placeholder";
import OrderCost from "../../components/OrderCost/OrderCost";

import styles from './Invoice.module.scss';

const Invoice = ({ order, isLoaded }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isLoaded) {
    return (
      <InvoicePlaceholder />
    )
  }

  if (!order) {
    navigate(`/table/${params.table}`);
    return null;
  }

  const costTotal = getOrderCost(order);
  const costLeft = getPaymentLeft(order);

  return (
    <>
      <Header logo />

      <Main>
        <div className={styles.Container}>
          <div className={styles.Title}><Title>Xaloc Tapas Bar</Title></div>
          <div className={styles.Price}>
            <OrderCost
              left={costLeft}
              total={costTotal}
            />
          </div>
          <div className={styles.Total}><Text>{t('Your table total')}</Text></div>

          <div className={styles.ButtonsList}>
            {order.status === 'local' ? (
              <Button
                appearance="dark"
                onClick={() => {

                }}
              >
                {t('Make order')}
              </Button>
            ) : (
              <Button
                appearance="dark"
                onClick={() => {
                  navigate(`/bill/${order.table.id}`);
                }}
              >
                {t('View & pay the bill')}
              </Button>
            )}

            <Button
              appearance="light"
              onClick={() => {
                navigate(`/menu/${order.table.id}`);
              }}
            >
              {t('View menu')}
            </Button>
          </div>
        </div>
        <PoweredBy/>
      </Main>
    </>
  );
}


export default () => {
  return (
    <Wrapper>
      <Invoice />
    </Wrapper>
  )
};

