import React from 'react';
import styles from './Main.module.css';

const Main = ({ className, children }) => {
  return (
    <div className={`${styles.Container} ${className}`}>
      {children}
    </div>
  );
}

export default Main;
