import _ from 'lodash';
import {CURRENCIES, DEMO_TABLE_ID} from './constants';

export const formatPrice = (value, currency) => {
  let currencySign = ''

  switch (currency) {
    case CURRENCIES.EURO:
      currencySign = '€';
      break;
    case CURRENCIES.DOLLAR:
      currencySign = '$';
      break;
    case CURRENCIES.ROUBLE:
      currencySign = '₽';
      break;
  }

  return `${currencySign}${Number(value).toFixed(2)}`;
}

export const getOrderCost = (order) => {
  return Number(_.sum(order.items.map((item) => item.price * item.quantity)).toFixed(2));
}

export const getPaidCost = (order) => {
  return Number(_.sum(order.transactions.filter((item) => item.status === 'accepted').map((item) => item.cost)).toFixed(2));
}

export const getPaymentLeft = (order) => {
  return Number(Number(getOrderCost(order) - getPaidCost(order)).toFixed(2))
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));
