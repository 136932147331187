import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Title from "../../components/UI/Title/Title";
import Text from "../../components/UI/Text/Text";
import Button from "../../components/UI/Button/Button";
import icon from './icon.svg';

import styles from "./Receipt.module.css";

const Receipt = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <Main>
        <div className={styles.result}>
          <img className={styles.img} src={icon} />

          <Title className={styles.title}>{t('The receipt has been sent')}</Title>
          <Text className={styles.text}>{t('Please check your email')}</Text>
          <div className={styles.btns}>
            <Button
              appearance="dark"
              onClick={() => {
                navigate(`/table/${params.table}`);
              }}
            >
              {t('Back to the home screen')}
            </Button>
          </div>
        </div>
        <PoweredBy />
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <Receipt />
    </Wrapper>
  )
};

