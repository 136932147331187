import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Wrapper from "../../components/Wrapper/Wrapper";
import Text from "../../components/UI/Text/Text";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import errorImageUrl from './404.svg';

import styles from "./NotFound.module.scss";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const backHome = () => navigate('/', { replace: true });

  return (
    <>
      <Header/>
      <Main>
        <div className={styles.Content}>
          <img className={styles.Image} src={errorImageUrl} />
          <div className={styles.Text}>
            <Title>Page not found</Title>
          </div>
          <div className={styles.Description}>
            <Text>The resource request could not be found on this server. Please try again.</Text>
          </div>
          <div className={styles.ButtonsList}>
            <Button appearance="dark" onClick={() => navigate(location.pathname, { replace: true })}>Retry</Button>
            <Button appearance="light" onClick={backHome}>Back to home screen</Button>
          </div>
        </div>
        <PoweredBy/>
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <NotFound />
    </Wrapper>
  )
};
