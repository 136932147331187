import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "Your table total": "Your table total",
      "View & pay the bill": "View & pay the bill",
      "View menu": "View menu",
      "Payment Method": "Payment Method",
      "Debit / Credit Card": "Debit / Credit Card",
      "Apple Pay": "Apple Pay",
      "Pay": "Pay",
      "Split the bill": "Split the bill",
      "Add a tip": "Add a tip",
      "Pay by debit/credit card": "Pay by debit/credit card",
      "Your card number": "Your card number",
      "Card Number": "Card number",
      "Exp. Date": "Exp. Date",
      "CVV": "CVV",
      "MM / YY": "MM / YY",
      "Menu": "Menu",
      "All": "All",
      "Appetizers": "Appetizers",
      "Salads": "Salads",
      "Combo Meals": "Combo Meals",
      "Hot Dog": "Hot Dog",
      "100% Grass fed beef, natural casing, our ketchup, mustard and relish": "100% Grass fed beef, natural casing, our ketchup, mustard and relish",
      "Prime Beef Tips + Points": "Prime Beef Tips + Points",
      "Melt in your month good and anything else that gets this to two lines of text": "Melt in your month good and anything else that gets this to two lines of text",
      "Giant Prawns": "Giant Prawns",
      "Massive prawns drenched in butter and anything else that gets this to two lines of text": "Massive prawns drenched in butter and anything else that gets this to two lines of text",
      "Seared Diver Scallop": "Seared Diver Scallop",
      "House made lardo served with blistered tomatoes and fresh arugula": "House made lardo served with blistered tomatoes and fresh arugula",
      "Chicken Thigns": "Chicken Thigns",
      "Cured Olives, Capers, Citrus and Wine infusion": "Cured Olives, Capers, Citrus and Wine infusion",
      "Russian": "Russian",
      "English": "English",
      "Spanish": "Spanish",
      "Pay custom amount": "Pay custom amount",
      "The maximum custom amount for for your table is": "The maximum custom amount for for your table is {{cost}}",
      "Enter custom amount": "Enter custom amount",
      "Custom Amount": "Custom Amount",
      "Confirm": "Confirm",
      "each": "{{cost}} / each",
      "Split evenly": "Split evenly",
      "Pay by items": "Pay by items",
      "Select how to split for your entire table, then encourage other guests to pay": "Select how to split for your entire table, then encourage other guests to pay",
      "Table": "Table {{number}}",
      "Total": "Total",
      "Please select items from the list for which you want will pay": "Please select items from the list for which you want to pay",
      "Select the number of guests": "Select the number of guests",
      "Add a custom tip": "Add a custom tip",
      "Continue with a tip": "Continue with a {{cost}} tip",
      "Continue without a tip": "Continue without a tip",
      "or": "or",
      "Pay_cost": "Pay {{cost}}",
      "Split the bill _cost": "Split the bill on {{cost}}",
      "By continuing, I accept the Terms of Use of Qruto.": "By continuing, I accept the <1>Terms of Use</1> of Qruto.",
      "Payment Successful": "Payment Successful",
      "Thank you for your trust! You can also request a duplicate receipt to your email": "Thank you for your trust! You can also request a duplicate receipt to your email",
      "Get a receipt": "Get a receipt",
      "Skip a receipt": "Skip a receipt",
      "Email": "Email",
      "Enter your email": "Enter your email",
      "Send": "Send",
      "The receipt has been sent": "The receipt has been sent",
      "Please check your email": "Please check your email",
      "Back to the home screen": "Back to the home screen",
      "Back to order": "Back to order",
      "Field is required": "Field is required",
      "Waiting for payment to proceed": "Waiting for payment to proceed",
      "it might take a while": "it might take a while",
      "Make order": "Make order",
      "Submit the order": "Submit the order {{cost}}",
      "Add to the bill": "Add to the bill",
      "Your table has not yet been invoiced": "Your table has not yet<br />been invoiced",
      "Most likely you haven't ordered anything yet. Please see our menu to make your order.": "Most likely you haven't ordered anything yet. Please see our menu to make your order.",
      "If you have already made an order, you should wait for a little while the bill is displayed in the system.": "If you have already made an order, you should wait for a little while the bill is displayed in the system.",
    }
  },
  ru: {
    translation: {
      "Your table total": "Сумма к оплате",
      "View & pay the bill": "Посмотреть и оплатить",
      "View menu": "Меню",
      "Payment Method": "Способ оплаты",
      "Debit / Credit Card": "Картой",
      "Apple Pay": "Apple Pay",
      "Pay": "Оплатить",
      "Split the bill": "Разделить счёт",
      "Add a tip": "Оставить чаевые",
      "Pay by debit/credit card": "Оплатить картой",
      "Your card number": "Номер вашей карты",
      "Card Number": "Номер карты",
      "Exp. Date": "Действует до",
      "CVV": "CVV",
      "MM / YY": "MM / YY",
      "Menu": "Меню",
      "All": "Все",
      "Appetizers": "Аппетайзеры",
      "Salads": "Салаты",
      "Combo Meals": "Комбо",
      "Hot Dog": "Хот-дог",
      "100% Grass fed beef, natural casing, our ketchup, mustard and relish": "100% Grass fed beef, natural casing, our ketchup, mustard and relish",
      "Prime Beef Tips + Points": "Prime Beef Tips + Points",
      "Melt in your month good and anything else that gets this to two lines of text": "Melt in your month good and anything else that gets this to two lines of text",
      "Giant Prawns": "Креветки",
      "Massive prawns drenched in butter and anything else that gets this to two lines of text": "Massive prawns drenched in butter and anything else that gets this to two lines of text",
      "Seared Diver Scallop": "Seared Diver Scallop",
      "House made lardo served with blistered tomatoes and fresh arugula": "House made lardo served with blistered tomatoes and fresh arugula",
      "Chicken Thigns": "Куриные наггетсы",
      "Cured Olives, Capers, Citrus and Wine infusion": "Cured Olives, Capers, Citrus and Wine infusion",
      "Russian": "Русский",
      "English": "Английский",
      "Spanish": "Испанский",
      "Pay custom amount": "Указать сумму",
      "The maximum custom amount for for your table is": "Максимальная сумма по вашему столику {{cost}}",
      "Enter custom amount": "Введите сумму",
      "Custom Amount": "Частичная оплата",
      "Confirm": "Подтвердить",
      "each": "{{cost}} / каждый",
      "Split evenly": "Разделить поровну",
      "Pay by items": "Выбрать блюда",
      "Select how to split for your entire table, then encourage other guests to pay": "Укажите как разделить счёт",
      "Table": "Стол {{number}}",
      "Total": "Всего",
      "Please select items from the list for which you want will pay": "Пожалуйста, выберите позиции которые хотите оплатить",
      "Select the number of guests": "Выберите число гостей",
      "Add a custom tip": "Указать свою сумму чаевых",
      "Continue with a tip": "Продолжить с {{cost}} чаевых",
      "Continue without a tip": "Продолжить без чаевых",
      "or": "или",
      "Pay _cost": "Оплатить {{cost}}",
      "Split the bill _cost": "Разделить счёт на {{cost}}",
      "By continuing, I accept the Terms of Use of Qruto.": "Продолжая, я соглашаюсь с <1>Правилами пользования</1> сервисом Qruto.",
      "Payment Successful": "Оплачено успешно",
      "Thank you for your trust! You can also request a duplicate receipt to your email": "Спасибо за доверие! Вы также можете запросить квитанцию на вашу электронную почту",
      "Get a receipt": "Получить квитанцию",
      "Skip a receipt": "Продолжить без квитанции",
      "Email": "Email",
      "Enter your email": "Укажите ваш email",
      "Send": "Отправить",
      "The receipt has been sent": "Квитанция отправлена",
      "Please check your email": "Проверьте свою электронную почту",
      "Back to the home screen": "Назад",
      "Back to order": "Назад к заказу",
      "Field is required": "Заполните поле",
      "Waiting for payment to proceed": "Ожидание завершения оплаты",
      "it might take a while": "это может занять некоторое время",
      "Make order": "Оформить заказ",
      "Submit the order": "Оформить заказ {{cost}}",
      "Add to the bill": "Добавить в заказ",
      "Your table has not yet been invoiced": "Счет за ваш стол<br />еще не выставлен",
      "Most likely you haven't ordered anything yet. Please see our menu to make your order.": "Скорее всего вы еще ничего не заказывали. Пожалуйста, ознакомьтесь с нашим меню, чтобы сделать заказ.",
      "If you have already made an order, you should wait for a little while the bill is displayed in the system.": "Если вы уже сделали заказ, вам следует немного подождать, пока в системе отобразится счет.",
    }
  },
  es: {
    translation: {
      "Your table total": "Importe a pagar",
      "View & pay the bill": "Ver y pagar la cuenta",
      "View menu": "Ver la carta",
      "Payment Method": "Métodos de pago",
      "Debit / Credit Card": "Tarjeta",
      "Apple Pay": "Apple Pay",
      "Pay": "Pagar",
      "Split the bill": "Dividir la cuenta",
      "Add a tip": "Dejar propina",
      "Pay by debit/credit card": "Pagar con tarjeta",
      "Your card number": "Número de tarjeta",
      "Card Number": "Número de tarjeta",
      "Exp. Date": "Válido hasta",
      "CVV": "CVV",
      "MM / YY": "MM / AA",
      "Menu": "La carta",
      "All": "Todo",
      "Appetizers": "Entrantes",
      "Salads": "Ensaladas",
      "Combo Meals": "Menus",
      "Hot Dog": "Hot dog",
      "100% Grass fed beef, natural casing, our ketchup, mustard and relish": "100% Grass fed beef, natural casing, our ketchup, mustard and relish",
      "Prime Beef Tips + Points": "Prime Beef Tips + Points",
      "Melt in your month good and anything else that gets this to two lines of text": "Melt in your month good and anything else that gets this to two lines of text",
      "Giant Prawns": "Креветки",
      "Massive prawns drenched in butter and anything else that gets this to two lines of text": "Massive prawns drenched in butter and anything else that gets this to two lines of text",
      "Seared Diver Scallop": "Seared Diver Scallop",
      "House made lardo served with blistered tomatoes and fresh arugula": "House made lardo served with blistered tomatoes and fresh arugula",
      "Chicken Thigns": "Куриные наггетсы",
      "Cured Olives, Capers, Citrus and Wine infusion": "Cured Olives, Capers, Citrus and Wine infusion",
      "Russian": "Ruso",
      "English": "Inglés ",
      "Spanish": "Español",
      "Pay custom amount": "Marcar el importe a pagar",
      "The maximum custom amount for for your table is": "El imposte máximo de su mesa es {{cost}}",
      "Enter custom amount": "Introducir el importe",
      "Custom Amount": "Marcar un importe",
      "Confirm": "Confirmar",
      "each": "{{cost}} / cada uno",
      "Split evenly": "Dividir en partes iguales",
      "Pay by items": "Pagar por artículos",
      "Select how to split for your entire table, then encourage other guests to pay": "¿Cómo quiere dividir la cuenta?",
      "Table": "Mesa {{number}}",
      "Total": "Total",
      "Please select items from the list for which you want will pay": "Seleccione los platos que quiere pagar",
      "Select the number of guests": "Marque la cantidad de clientes",
      "Add a custom tip": "Marcar el importe aleatorio",
      "Continue with a tip": "Dejar {{cost}} de propina",
      "Continue without a tip": "Continuar sin propina",
      "or": "0",
      "Pay _cost": "Pagar {{cost}}",
      "Split the bill _cost": "Pagar {{cost}}",
      "By continuing, I accept the Terms of Use of Qruto.": "Al continuar, Acepto los <1>Términos los Términos Uso</1> de Qruto.",
      "Payment Successful": "Pago realizado con éxito",
      "Thank you for your trust! You can also request a duplicate receipt to your email": "Gracias por confiar en Qruto.",
      "Get a receipt": "Solicitar el recibo",
      "Skip a receipt": "Volver al inicio",
      "Email": "Correo electrónico",
      "Enter your email": "Introduzca su correo electrónico",
      "Send": "Enviar",
      "The receipt has been sent": "El recibo ha sido enviado",
      "Please check your email": "El recibo ha sido enviado",
      "Back to the home screen": "Volver al inicio",
      "Back to order": "Volver al pedido",
      "Field is required": "Se requiere campo",
      "Waiting for payment to proceed": "Esperando a que se complete el pago",
      "it might take a while": "Podría tomar un tiempo",
      "Make order": "Hacer orden",
      "Submit the order": "Enviar el pedido {{cost}}",
      "Add to the bill": "Añadir a la factura",
      "Your table has not yet been invoiced": "Tu mesa aún<br />no ha sido facturada",
      "Most likely you haven't ordered anything yet. Please see our menu to make your order.": "Lo más probable es que aún no hayas pedido nada. Consulte nuestro menú para realizar su pedido.",
      "If you have already made an order, you should wait for a little while the bill is displayed in the system.": "Si ya realizó un pedido, debe esperar un poco mientras se muestra la factura en el sistema.",
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "es",
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
