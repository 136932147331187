import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@rsuite/icons';
import {useNavigate, useParams} from "react-router-dom";
import {
  FaEuroSign as FaEuroSignIcon
} from 'react-icons/fa';
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import Text from "../../components/UI/Text/Text";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Wrapper from "../../components/Wrapper/Wrapper";
import Input from "../../components/UI/Input/Input";
import { CURRENCIES } from "../../utils/constants";
import { formatPrice, getOrderCost } from "../../utils/common";
import { InvoicePlaceholder } from "../../components/Placeholder/Placeholder";
import apiClient from "../../utils/apiClient";
import Modal from "../../components/UI/Modal/Modal";

import styles from './Tips.module.scss';

const Tips = ({ order, isLoaded }) => {
  const params = useParams();

  const [tipPercent, setTipPercent] = useState(15);
  const [customTipAmount, setCustomTipAmount] = useState(0);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [paymentModal, setPaymentModal] = useState({
    isVisible: false,
    data: {},
  });

  const [isPayModalLoading, setIsPayModalLoading] = useState(null);

  const url = new URL(window.location.href);

  const onSelectByPredefined = (value) => {
    setTipPercent(value);
    setCustomTipAmount(0);
  }

  const onChangeByInput = (value) => {
    if (value) {
      setCustomTipAmount(value);
      setTipPercent(null);
    } else {
      setTipPercent(15);
    }
  }

  const onPayClick = async () => {
    setIsPayModalLoading(true);

    const amount = Number(Number(Number(Number(customTipAmount).toFixed(2)) * 100).toFixed(2));

    const response = await apiClient.post('/payment/tips/form', {
      orderId: order.id,
      merchant: {
        "operationType": 1,
        "terminal": 44767,
        "payment": {
          "terminal": 44767,
          "secure": 1,
          "amount": amount,
          "currency": "EUR",
          "order": `${+new Date()}`,
          "urlOk": `${url.origin}/payment-awaiting/tips/${order.table.id}`,
        }
      }
    });

    setIsPayModalLoading(false);

    setPaymentModal({
      isVisible: true,
      data: {
        challengeUrl: response.data.challengeUrl,
      },
    });
  }

  useEffect(() => {
    if (order) {
      const cost = getOrderCost(order);

      if (tipPercent) {
        setCustomTipAmount(cost * tipPercent / 100)
      }
    }
  }, [tipPercent, order]);

  if (!isLoaded) {
    return (
      <InvoicePlaceholder />
    )
  }

  if (!order) {
    navigate(`/table/${params.table}`);
    return null;
  }

  const cost = getOrderCost(order);

  return (
    <>
      <Header />

      <Main>
        <Modal
          isVisible={paymentModal.isVisible}
          onClose={() => setPaymentModal({ isVisible: false, data: {} })}
          title={<Title bolder>{t('Pay by debit/credit card')}</Title>}
          classes={{
            modal: styles.Modal,
            header: styles.ModalHeader,
            body: styles.ModalBody,
          }}
        >
          <iframe
            className={styles.Iframe}
            src={paymentModal.data.challengeUrl}
          />
        </Modal>

        <div className={styles.Content}>
          <Text>{t('Table', { number: order.table.number })}</Text>
          <div className={styles.title}>
            <Title bolder>{t('Add a tip')}</Title>
          </div>
          <div className={styles.variants}>
            <input type="radio" name="variant" id="v1" onChange={() => onSelectByPredefined(15)} checked={tipPercent === 15} />
            <label className={styles.variant} htmlFor={'v1'}>
              <span className={styles.percent}>15%</span>
              <span className={styles.amount}>{formatPrice(cost * 0.15, CURRENCIES.EURO)}</span>
            </label>
            <input type="radio" name="variant" id="v2" onChange={() => onSelectByPredefined(18)} checked={tipPercent === 18} />
            <label className={styles.variant} htmlFor={'v2'}>
              <span className={styles.percent}>18%</span>
              <span className={styles.amount}>{formatPrice(cost * 0.18, CURRENCIES.EURO)}</span>
            </label>
            <input type="radio" name="variant" id="v3" onChange={()=> onSelectByPredefined(20)} checked={tipPercent === 20} />
            <label className={styles.variant} htmlFor={'v3'}>
              <span className={styles.percent}>20%</span>
              <span className={styles.amount}>{formatPrice(cost * 0.2, CURRENCIES.EURO)}</span>
            </label>
            <input type="radio" name="variant" id="v4" onChange={() => onSelectByPredefined(25)} checked={tipPercent === 25} />
            <label className={styles.variant} htmlFor={'v4'}>
              <span className={styles.percent}>25%</span>
              <span className={styles.amount}>{formatPrice(cost * 0.25, CURRENCIES.EURO)}</span>
            </label>
          </div>
          <div className={styles.divider}>
            <span className={styles.dividerLine}/>
            <span className={styles.dividerText}>{t('or')}</span>
            <span className={styles.dividerLine}/>
          </div>
          <div className={styles.custom}>
            <Input
              type="number"
              name={'tips'}
              id={'tips'}
              placeholder={'0.00'}
              disabled={false}
              label={t('Add a custom tip')}
              required
              icon={<Icon as={FaEuroSignIcon} size="1.5em" />}
              onChange={(e) => {
                const value = Number(e.target.value);

                onChangeByInput(value);
              }}
            />
          </div>
          <div className={styles.btns}>
            <Button
              appearance="dark"
              loading={isPayModalLoading}
              onClick={onPayClick}
            >
              {t('Continue with a tip', { cost: formatPrice(customTipAmount, CURRENCIES.EURO) })}
            </Button>
            <Button
              appearance="light"
              onClick={() => {
                navigate(`/bill/${order.table.id}`);
              }}
            >
              {t('Continue without a tip')}
            </Button>
          </div>
        </div>
        <PoweredBy/>
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <Tips />
    </Wrapper>
  )
};
