import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import Header from "../Header/Header";
import PoweredBy from "../PoweredBy/PoweredBy";

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './Placeholder.module.scss';

export const InvoicePlaceholder = () => {
  return (
    <div className={cx(styles.Container, styles.Invoice)}>
      <Header />

      <div className={styles.Body}>
        <Skeleton
          width={75}
          height={75}
          className={styles.Avatar}
          style={{ borderRadius: 12 }}
        />

        <div className={styles.Content}>
          <Skeleton
            height={20}
            width={200}
          />

          <Skeleton
            height={30}
            width={100}
          />

          <Skeleton
            height={20}
            width={140}
          />

          <div className={styles.Buttons}>
            <Skeleton
              height={50}
            />

            <Skeleton
              height={50}
            />
          </div>
        </div>

      </div>
      <PoweredBy />
    </div>
  );
}

export const MenuTopPlaceholder = () => {
  return (
    <div className={styles.TopHorizontal}>
      <Skeleton
        height={40}
        width={40}
        style={{ borderRadius: 16 }}
      />
      <Skeleton
        height={40}
        width={70}
        style={{ borderRadius: 16 }}
      />
      <Skeleton
        height={40}
        width={90}
        style={{ borderRadius: 16 }}
      />

      <Skeleton
        height={40}
        width={60}
        style={{ borderRadius: 16 }}
      />

      <Skeleton
        height={40}
        width={110}
        style={{ borderRadius: 16 }}
      />
    </div>
  )
}

export const MenuPositionsPlaceholder = () => {
  return (
    <div className={styles.Positions}>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={60}
        />
        <Skeleton
          inline
          height={60}
          width={40}
        />
      </div>
      <div className={styles.Position}>
        <Skeleton
          inline
          height={40}
        />
        <Skeleton
          inline
          height={40}
          width={40}
        />
      </div>
    </div>
  )
}

