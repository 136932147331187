import React from 'react';
import restaurantLogoImageUrl from './logo.svg';

import styles from './Logo.module.css';

const Logo = () => {
  return (
    <div className={styles.Logo}>
      <img src={restaurantLogoImageUrl} />
    </div>
  );
}

export default Logo;
