import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Table from "./pages/Table/Table";
import Menu from "./pages/Menu/Menu";
import PaySuccess from "./pages/PaySuccess/PaySuccess";
import PayFailed from "./pages/PayFailed/PayFailed";
import Receipt from "./pages/Receipt/Receipt";
import SplitBill from "./pages/SplitBill/SplitBill";
import NotFound from "./pages/NotFound/NotFound";
import Bill from "./pages/Bill/Bill";
import Tips from "./pages/Tips/Tips";
import Invoice from "./pages/Invoice/Invoice";
import { DEMO_TABLE_ID } from "./utils/constants";
import PaymentAwaiting from "./pages/PaymentAwaiting/PaymentAwaiting";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            path=""
            element={
              <Navigate replace to={`/table/${DEMO_TABLE_ID}`} />
            }
          />
          <Route
            path="demo"
            element={
              <Navigate replace to={`/table/${DEMO_TABLE_ID}`} />
            }
          />
          <Route exact path="table/:table" element={<Table />}/>
          <Route exact path="invoice/:table" element={<Invoice />} />
          <Route exact path="bill/:table" element={<Bill/>} />
          <Route exact path="payment-awaiting/:table" element={<PaymentAwaiting/>}/>
          <Route exact path="pay-success/:table/:order/:transaction" element={<PaySuccess/>}/>
          <Route exact path="pay-failed/:table" element={<PayFailed/>}/>
          <Route exact path="menu/:table" element={<Menu/>}/>
          <Route exact path="receipt/:table" element={<Receipt/>}/>
          <Route exact path="split/:table" element={<SplitBill/>}/>
          <Route exact path="tips/:table" element={<Tips/>}/>
        </Route>

        <Route path="*" element={<NotFound />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
