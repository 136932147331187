import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Loader } from 'rsuite';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Elements, CardElement, PaymentRequestButtonElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Title from "../../components/UI/Title/Title";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import Text from "../../components/UI/Text/Text";
import Button from "../../components/UI/Button/Button";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Wrapper from "../../components/Wrapper/Wrapper";
import Modal from "../../components/UI/Modal/Modal";
import {
  Buttons,
  PaymentTop,
  PaymentRow,
  Price
} from "../../components/PaymentScreen/PaymentScreen";
import {CURRENCIES, DEMO_TABLE_ID} from "../../utils/constants";
import {formatPrice, getOrderCost, getPaymentLeft, sleep} from "../../utils/common";
import apiClient from "../../utils/apiClient";
import { InvoicePlaceholder } from "../../components/Placeholder/Placeholder";
import OrderCost from "../../components/OrderCost/OrderCost";

import styles from './Bill.module.scss';

const getStripe = (isTest) => {
  if (isTest) return loadStripe('pk_test_51KhAONJ1ILDi8hMd0f8BxyynXnnCLl7nJLT2VIfWl95rzZVUXYndDC9B6hgR0t8mWX5VHGQAThChvlULWt2nKzUG00T36g9Duy');

  return process.env.NODE_ENV === 'development'
    ? loadStripe('pk_test_51KhAONJ1ILDi8hMd0f8BxyynXnnCLl7nJLT2VIfWl95rzZVUXYndDC9B6hgR0t8mWX5VHGQAThChvlULWt2nKzUG00T36g9Duy')
    : loadStripe('pk_live_51KhAONJ1ILDi8hMds9NbWVDn2N2iOrw0sZrcWjRBWAh75nvRTJB1zzo9WL7O4u0txeMmexE9GwdF8K0gbyvRmw0N00o3DbttEZ');
}

const PayText = ({
  customAmount,
  leftCost,
}) => {
  const { t } = useTranslation();

  const toPay = (!customAmount || customAmount === leftCost)
    ? formatPrice(leftCost, CURRENCIES.EURO) :
    <>
      <span className={styles.oldAmount}>{formatPrice(leftCost, CURRENCIES.EURO)}</span>
      &nbsp;
      {formatPrice(customAmount, CURRENCIES.EURO)}
    </>;

  return (
    <>
      {t('Pay')}
      &nbsp;
      {toPay}
    </>
  )
}

const CheckoutForm = ({ amountToPay, tableId, transactionId }) => {
  const [isPaymentElementLoading, setPaymentElementLoading] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsPaymentProcessing(true);

    if (tableId === DEMO_TABLE_ID) {
      await sleep(3000);
      navigate(`/payment-awaiting/${tableId}?transactionId=${transactionId}`);
    } else {
      const paymentResponse = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-awaiting/${tableId}?transactionId=${transactionId}`,
          payment_method_data: {
            billing_details: {
              name: null,
              email: null,
              phone: null,
              address: {
                country: null,
                postal_code: null,
                state: null,
                city: null,
                line1: null,
                line2: null,
              },
            },
          }
        },
      });

      setIsPaymentProcessing(false);

      if (paymentResponse.error) {
        navigate(`/pay-failed/${tableId}`);
      }
    }
  };

  const isPaymentElementLoaded = isPaymentElementLoading === false;

  return (
    <form onSubmit={handleSubmit} className={styles.CheckoutForm}>
      <div className={styles.CheckoutFormItem}>
        {!isPaymentElementLoaded && (
          <Loader size="md" />
        )}

        <PaymentElement
          onReady={() => {
            setPaymentElementLoading(false);
          }}
          options={{
            fields: {
              billingDetails: 'never'
            },
            cssSrc: 'https://fonts.googleapis.com/css?family=SF+Pro+Text',
            style: {
              base: {
                color: '#1A1A1A',
                fontWeight: '500',
                fontFamily: 'SF Pro Text, Open Sans, sans-serif',
                fontSize: '18px',
              },
            }
          }}
        />
      </div>

      <div className={styles.CheckoutFormItem}>
        <Button
          disabled={!isPaymentElementLoaded}
          className={styles.StripePayButton}
          appearance="dark"
          loading={isPaymentProcessing}
          type="submit"
        >
          {t('Pay _cost', { cost: formatPrice(amountToPay, CURRENCIES.EURO) })}
        </Button>
      </div>
    </form>
  )
}

const Bill = ({ order, isLoaded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [isPayModalLoading, setIsPayModalLoading] = useState(null);
  const [paymentModal, setPaymentModal] = useState({ isVisible: false, data: {} });

  if (!isLoaded) {
    return (
      <InvoicePlaceholder />
    )
  }

  const { customAmount } = qs.parse(location.search);

  const costLeft = getPaymentLeft(order);

  const acceptedTransactions = order.transactions
    .filter((item) => item.status === 'accepted');

  const amountToPay = acceptedTransactions.length === 2 ? costLeft : (customAmount ? Number(customAmount) : costLeft);

  const onPayClick = async () => {
    setIsPayModalLoading(true);

    const intentResponse = await apiClient.post('/payment/intent', {
      orderId: order.id,
      type: 'payment',
      amount: amountToPay,
    });

    const stripe = getStripe(order.table.id === DEMO_TABLE_ID);

    setPaymentModal({
      isVisible: true,
      data: {
        paymentIntent: intentResponse.data.paymentIntent,
        stripe,
      },
    });

    setIsPayModalLoading(false);
  }

  if (!order) {
    navigate(`/table/${order.table.id}`);
    return null;
  }

  const totalCost = getOrderCost(order);
  const leftCost = getPaymentLeft(order);

  return (
    <>
      <Header logo />
      <Main>
        <Modal
          isVisible={paymentModal.isVisible}
          onClose={() => setPaymentModal({ isVisible: false, data: {} })}
          title={<Title bolder>{t('Pay by debit/credit card')}</Title>}
          classes={{
            modal: styles.Modal,
            header: styles.ModalHeader,
            body: styles.ModalBody,
          }}
        >
          <Elements
            stripe={paymentModal.data.stripe || null}
            options={{
              clientSecret: paymentModal.data.paymentIntent?.client_secret,
              appearance: {
                labels: 'floating',
                variables: {
                  fontFamily: 'SF Pro Text',
                  fontLineHeight: '1.5',
                  colorPrimary: '#FF5861',
                  colorPrimaryText: '#1A1A1A',
                },
              }
            }}
          >
            <CheckoutForm
              tableId={order.table.id}
              transactionId={Number(paymentModal.data.paymentIntent?.metadata?.transactionId)}
              amountToPay={amountToPay}
            />
          </Elements>
        </Modal>

        <div className={styles.Content}>
          <PaymentTop>
            <Text>{t('Your table total')}</Text>
            <OrderCost
              total={totalCost}
              left={leftCost}
            />
          </PaymentTop>

          <div className={styles.Bill}>
            <div className={styles.BillItemsListTop} />
            <div className={styles.BillItemsList}>
              {order.items.map((item, index) => (
                <PaymentRow key={index}>
                  <Text>{item.name} <span className="text-primary">x {item.quantity}</span></Text>
                  <Price>{formatPrice(item.price, CURRENCIES.EURO)}</Price>
                </PaymentRow>
              ))}
            </div>
            <div className={styles.BillItemsListBottom}/>
          </div>

          <Buttons>
            <Button
              appearance="dark"
              onClick={onPayClick}
              loading={isPayModalLoading}
            >
              <PayText
                customAmount={amountToPay}
                leftCost={leftCost}
              />
            </Button>

            <Button
              appearance="light"
              onClick={() => {
                navigate(`/split/${order.table.id}`);
              }}
            >
              {t('Split the bill')}
            </Button>
            <Button
              appearance="transparent"
              onClick={() => {
                navigate(`/tips/${order.table.id}`);
              }}
            >
              {t('Add a tip')}
            </Button>

            <div className={styles.Terms}>
              <Trans i18nKey="By continuing, I accept the Terms of Use of Qruto.">
                By continuing, I accept the <a href="https://qruto.co/es/policy" target="_blank">Terms of Use</a> of Qruto.
              </Trans>
            </div>
          </Buttons>
        </div>

        <PoweredBy/>
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <Bill />
    </Wrapper>
  )
};

