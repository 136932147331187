import React from 'react';
import logoImageUrl from './images/logo.svg';
import styles from './PoweredBy.module.css';

const PoweredBy = () => {
  return (
    <div className={styles.PoweredBy}>
      <a href="https://qruto.co">
        <img src={logoImageUrl} className={styles.Image} />
      </a>
    </div>
  );
}

export default PoweredBy;
