import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper/Wrapper";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Title from "../../components/UI/Title/Title";
import Text from "../../components/UI/Text/Text";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Modal from "../../components/UI/Modal/Modal";
import apiClient from "../../utils/apiClient";
import iconImageUrl from './icon.svg';

import styles from './PaySuccess.module.scss';

const PaySuccess = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, actions) => {
      await apiClient.post('/payment/invoice', {
        email: values.email,
        orderId: params.order,
        transactionId: Number(params.transaction),
      });

      actions.setSubmitting(false);

      navigate(`/receipt/${params.table}`);
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = t('Field is required')
      }

      return errors;
    },
  });

  return (
    <>
      <Header />

      <Main>
        <Modal
          isVisible={isReceiptModalVisible}
          onClose={() => setIsReceiptModalVisible(false)}
          title={<Title bolder>{t('Get a receipt')}</Title>}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.Input}>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder={t('Enter your email')}
                label={t('Email')}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            <Button
              className={styles.SubmitButton}
              loading={formik.isSubmitting}
              disabled={!formik.values.email || formik.isSubmitting}
              appearance="dark"
              type="submit"
            >
              {t('Send')}
            </Button>
          </form>
        </Modal>

        <div className={styles.Content}>
          <img className={styles.Image} src={iconImageUrl} />

          <Title className={styles.Title}>{t('Payment Successful')}</Title>
          <Text className={styles.Description}>{t('Thank you for your trust! You can also request a duplicate receipt to your email')}</Text>

          <div className={styles.ButtonsList}>
            <Button
              appearance="dark"
              onClick={() => {
                setIsReceiptModalVisible(true);
              }}
            >
              {t('Get a receipt')}
            </Button>

            <Button
              appearance="light"
              onClick={() => {
                navigate(`/table/${params.table}`);
              }}
            >
              {t('Skip a receipt')}
            </Button>
          </div>
        </div>

        <PoweredBy />
      </Main>
    </>
  );
}

export default () => {
  return (
    <Wrapper>
      <PaySuccess />
    </Wrapper>
  )
};
